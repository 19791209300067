.paginate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(102, 102, 102, .1);
    border-left: 0;
    border-right: 0;
    padding: 8px 0;
    a {
        color: $color-text-dark;
    }
    &-pages {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-right: 20px;
        }
    }
    &-next {
        a::after {
            content: url(/img/ui/next.svg);
            margin-left: 5px;
            margin-top: 2px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    &-prev {
        a::before {
            content: url(/img/ui/next.svg);
            margin-right: 5px;
            margin-top: 2px;
            display: inline-block;
            vertical-align: middle;
            transform: scale(-1,1);
        }
    }

    .delimiter {
        //border-right: 1px solid black;
        border-left: 1px solid $color-text-dark;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
