.searchbar {
    margin: 25px auto 0 auto;
    input,
    button {
        width: 100%;
        margin-bottom: 20px;
        font-size: 1.4rem;
        height: 41px;
        @include responsive-md() {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    input {
        background-color: $color-white;
        color: $color-black;
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        border-radius: 25px;
        padding: 10px 15px;
        text-align: left;
        font-size: 1.4rem;
        border: 0;
        @include responsive-md() {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: thin solid $color-devil-gray;
        }
    }

    &-submit {
        button {
            position: relative;
            z-index: 1;
        }
    }

    // Change placeholder color with the non-standard attribute '::placeholder'
    ::placeholder {
        //Chrome, Firefox, Opera, Safari 10.1+
        color: $color-black;
        // Firefox
        opacity: 1;
    }
    :-ms-input-placeholder {
        // Internet Explorer 10-11
        color: $color-black;
    }
    ::-ms-input-placeholder {
        // Microsoft Edge
        color: $color-black;
    }

    @include responsive-md() {
        display: flex;
        max-width: 750px;
        position: relative;
        z-index: 1;
        #js-categories-popin-expand,
        #js-localisation-popin-expand {
            border-radius: 0;
        }
        #js-categories-popin-expand {
            border-right: thin solid $color-devil-gray;
        }
        &-keywords {
            width: 30%;
        }
        &-categories,
        &-localisation {
            width: 25%;
        }
        &-submit {
            width: 20%;
        }
    }
}
