// Bootstrap's breadcrumb overload
.breadcrumb {
    background-color: transparent;
    font-size: 1.2rem;
    padding: 0;
    &-item {
        a {
            color: $color-devil-gray;
        }
        + .breadcrumb-item {
            &::before {
                content: '-';
                margin: 0 5px;
            }
        }
    }
    @include responsive-lg() {
        font-size: 1.4rem;
    }
}
