.share {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12px;
    a {
        display: inline-block;
        height: 12px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        color: initial;
        flex: 1 0 auto;
    }
    &-facebook {
        width: 8px;
        background-image: url(/img/ui/facebook.svg);
    }
    &-twitter {
        width: 14px;
        margin: 0 25px;
        background-image: url(/img/ui/twitter.svg);
    }
    &-linkedin {
        width: 15px;
        margin-right: 25px;
        background-image: url(/img/ui/linkedin.svg);
    }
    &-viadeo {
        width: 15px;
        background-image: url(/img/ui/viadeo.svg);
    }
}
