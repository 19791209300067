.pageerror {
    &-container {
        max-width: 420px;
        margin-right: auto;
        margin-left: auto;
        padding: 8rem !important;
    }

    &-centerer {
        text-align: center;
    }

    &-code {
        color: #2156cd;
        font-size: 12rem;
        font-weight: 600;
        line-height: 10rem;
    }

    &-title {
        color: #333;
        font-size: 3rem;
    }
}
