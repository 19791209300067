.business {
    &-summary {
        margin-top: -145px;
        margin: -145px 0 0;
        position: relative;
        background-color: $color-white;
        color: $color-devil-gray;
        padding: 53px 0 15px;
        border-radius: 10px;
        &--vif {
            &::before {
                content: '';
                font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                display: block;
                width: 66px;
                height: 66px;
                background: url(/img/ui/vif.svg) center center;
                background-size: 100%;
                position: absolute;
                top: -1px;
                right: -1px;
            }
        }
        &-introduction {
            text-align: center;
            margin-bottom: 30px;
        }
        &-details {
            line-height: 17px;
            h4 {
                font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            }
            &-address {
                &::first-line {
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                    color: $color-text-dark;
                }
            }
            &-logo {
                width: 30%;
                margin-right: 15px;
                @include responsive-lg() {
                    order: -1;
                }
                img {
                    width: 100%;
                }
            }
            &-pictos {
                display: flex;
                justify-content: space-around;
                margin-top: 15px;
                a {
                    width: 10px;
                    height: 10px;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                &-onlinestore {
                    background-image: url(/img/ui/paiement.svg);
                }
                &-video {
                    background-image: url(/img/ui/video.svg);
                }
                &-building {
                    background-image: url(/img/ui/travaux.svg);
                }
            }
            &-contact {
                list-style: none;
                padding: 0;
                line-height: 24px;

                @include responsive-lg() {
                    text-align: center;
                }

                &-phone,
                &-fax {
                    padding-left: 15px;
                    position: relative;
                    &::before {
                        position: absolute;
                        top : 0;
                        left: 0;
                    }
                }
                &-phone {
                    &::before {
                        content: url(/img/ui/phone.svg);
                    }
                }
                &-fax {
                    &::before {
                        content: url(/img/ui/fax.svg);
                    }
                }
                @include responsive-lg() {
                    margin-left: calc(30% + 35px);
                }
            }
            &-reputation {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                a {
                    color: $color-orange;
                }
                @include responsive-lg() {
                    .share {
                        margin-left: 125px;
                    }
                }
                &-evaluation {
                    color: $color-blue;
                    p {
                        display: inline-block;
                        span {
                            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                        }
                    }
                    &::after {
                        content: url(/img/ui/star.svg);
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 8px;
                    }
                }
            }
        }
        &-map {
            margin-top: 20px;
            width: 100%;
            height: 235px;
        }
        &-update {
            font-size: 1.2rem;
            text-align: right;
            color: $color-silver;
        }
        &-cta {
            width: 100%;
            display: inline-block;
            text-align: center;
            @include responsive-lg() {
                width: initial;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                font-size: 1.1rem;
                padding: 20px 50px;
                bottom: -45px;
            }
        }
        @include responsive-lg() {
            margin-top: -15px;
        }
    }
    &-details {
        margin-top: 35px;
        margin-bottom: 60px;
        .card-body {
            background-color: transparent;
            img {
                border-radius: 5px;
                width: 100%;
            }
        }
        @include responsive-lg() {
            margin-top: 85px;
            .nav-link {
                background-color: transparent;
                padding: 0;
                text-transform: initial;
                font-size: 1.8rem;
                letter-spacing: initial;
                color: $color-silver;
                font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
                margin-bottom: 30px;
                &.active {
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                    color: $color-text-dark;
                    background: transparent;
                }
            }
        }
    }
    &-related {
        margin-bottom: 45px;
    }
    &-asideactivity {
        h2 {
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            font-size: 1.4rem;
            color: $color-text-dark;
            span {
                font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
                margin-left: 7px;
            }
        }
        @include responsive-lg() {
            &-lastmembers {
                padding-right: 35px;
            }
            &-lastnews {
                padding-left: 35px;
            }
        }
    }

    .card-header {
        .button {
            background-color: transparent;
            padding: 0;
            text-transform: initial;
            font-size: 1.8rem;
            letter-spacing: initial;
            &[aria-expanded="false"] {
                color: $color-silver;
                font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
            }
        }
    }
}

.getComptePhone {
    display: inline-block;
}