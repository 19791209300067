.homepage {
    &-lastmembers,
    &-lastnews {
        .nav {
            font-size: 1.8rem;
            display: flex;
            flex-wrap: nowrap;
            overflow-y: auto;
            &-link {
                margin-right: 15px;
                color: $color-half-devil-gray;
                opacity: .5;
                &.active {
                    color: $color-blue;
                    opacity: 1;
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                }
            }
        }
    }
    &-lastmembers {
        border-bottom: 1px solid rgba(102, 102, 102, .1);
        @include responsive-lg() {
            padding-right: 70px;
        }
    }
    &-lastnews {
        @include responsive-lg() {
            padding-left: 70px;
            border-bottom: 1px solid rgba(102, 102, 102, .1);
        }
    }
    &-links {
        display: flex;
        .homepage-links-column {
            &--hideOnMobile {
                display: none;
            }
            @include responsive-lg {
                display: block;
            }
        }
        border-top: 1px solid rgba(102, 102, 102, .1);
        padding: 35px 0 0;

        h2 {
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            margin-bottom: 15px;
        }
        &-list {
            list-style: none;
            font-size: 1.4rem;
            padding: 0;
            columns: 2;
            li {
                a {
                    color: $color-text-dark;
                    h3 {
                        font-size: inherit;
                    }
                }
            }
        }
    }
}
