html,
body {
    overflow-x: hidden;
    font-size: 62.5%;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
    color: $color-devil-gray;
    background-color: $color-background;
    -webkit-overflow-scrolling: touch;
    font-size: 1.4rem;
}

.container-wrapper {
    max-width: 1340px;
    margin: 80px auto 0 auto;

    &--pullTop {
        @include responsive-md() {
            margin-top: 0px;
        }
        @include responsive-lg() {
            margin-top: -125px;
        }
    }
}

.pagewrapper {
    margin-top: 0;
    @include responsive-lg() {
        border-radius: 10px;
        background-color: $color-background;
        position: relative;
        &--headerfixed {
            padding-top: 480px;
        }
    }
}

h1 {
    font-size: 2rem;
    color: $color-blue;
    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
    @include responsive-lg() {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

.lined-heading {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 0 0 30px 0;
    font-size: 2rem;
    color: $color-blue;
    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;

    span {
        background: $color-background;
        padding: 0 15px;
        position: relative;
        z-index: 1;
    }
    &::before {
        background: $color-devil-gray;
        opacity: .1;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        top: 50%;
        width: 100%;
        left: 0;
    }
    @include responsive-lg() {
        margin: 0 0 30px 0;
        font-size: 3rem;
    }
}

a {
    cursor: pointer!important;
}

.simplepage,
.post,
.postlist,
.reportissue {
    .container-fluid {
        padding: 0;
    }
}

@font-face {
    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
    src: url('/fonts/FuturaPTDemi.eot');
    src: local('☺'), url('/fonts/FuturaPTDemi.woff') format('woff'), url('/fonts/FuturaPTDemi.ttf') format('truetype'), url('/fonts/FuturaPTDemi.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
    src: url('/fonts/FuturaPTBook.eot');
    src: local('☺'), url('/fonts/FuturaPTBook.woff') format('woff'), url('/fonts/FuturaPTBook.ttf') format('truetype'), url('/fonts/FuturaPTBook.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.clear {
    clear: both;
    width: 100%;
    height: 0;
}

.chameleon-link {
    a, a:active, a:hover {
        color: inherit;
    }
}

.form-error {
    color: red;
    font-weight: bold;
}

.form-aide {
    font-style: italic;
}

.required:after {
    content: ' *';
}
