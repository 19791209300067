.post {
    h1 {
        margin-bottom: 55px;
    }
    &-paragraph {
        margin-bottom: 30px;
        &-picture {
            img {
                margin: 0 auto;
                display: block;
                max-width: 100%;
            }
        }
        &--img-left,
        &--img-right {
            h2 {
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
        &--img-top {
            img {
                margin: auto;
                display: block;
            }
        }
        &--img-float-right {
            .post-paragraph-text {
                img {
                    width: 100%;
                    @include responsive-lg() {
                        width: initial;
                        float: right;
                        margin: 15px;
                    }
                }
            }
        }
        &--img-float-top-right {
            .post-paragraph-text {
                position: relative;
                min-height: 250px;

                img {
                    position: relative;
                    display: block;
                    margin: auto;
                }

                @include responsive-lg () {
                    padding-right: 250px;
                    img, iframe {
                        position: absolute;
                        right: 0;
                        top: 0;
                        max-width: 250px;
                    }
                }
            }
        }
        &--img-float-left {
            .post-paragraph-text {
                img {
                    width: 100%;
                    @include responsive-lg() {
                        width: initial;
                        float: left;
                        margin: 15px;
                    }
                }
            }
        }
        &--img-float-top-left {
            .post-paragraph-text {
                position: relative;
                min-height: 250px;

                img {
                    position: relative;
                    display: block;
                    margin: auto;
                }

                @include responsive-lg () {
                    padding-left: 250px;
                    img, iframe {
                        position: absolute;
                        left: 0;
                        top: 0;
                        max-width: 250px;
                    }
                }
            }
        }
    }
}
