.bandeau-rgpd {
    color: white;
    background-color: rgba(30, 75, 165, 0.6);
    padding: 5px 16px;
    text-align: center;
    min-height: 25px;
    position: fixed;
    width: 100%;
    bottom: 0;

    a, a:hover, a:visited, a:active {
        color: white;
        text-decoration: none;
    }

    &-ok {
        font-weight: bold;
        display: inline-block;
        margin-left: 100px;
        cursor: pointer;
    }
}