.separator {
    height: 1px;
    background-color: $color-white;
    opacity: .3;
    width: 50px;
}

.has-not-bullets {
    list-style: none;
    padding: 0;
}

.is-full-width {
    width: 100%;
}

.border {
    &--left {
        border-left: 2px solid #3c8dbc !important;
    }

    &--right {
        border-right: 2px solid #3c8dbc !important;
    }
}