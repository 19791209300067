.link {
    color: $color-blue;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
        color: $color-blue;
        text-decoration: none;
    }
    &-endsection {
        display: inline-block;
        font-size: 1.1rem;
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        letter-spacing: 1.57px;
        text-transform: uppercase;
        margin-top: 60px;
        &--small {
            font-size: 0.8rem;
            letter-spacing: 1.14px;
            margin-top: 20px;
        }
    }
}
