// Hack to avoid carousels in bootstrap's tabs to take place when hidden
.tab-content>.tab-pane {
    display: none!important;
}
.tab-content>.active {
    display: block!important;
}

// Replace 100% by 100vw
.owl-carousel {
    width: 100vw;
}
