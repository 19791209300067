.businesslist {
    &-categories {
        position: relative;
        margin-top: -135px;
        button {
            width: 100%;
            padding: 15px 20px;
            text-align: left;
            font-size: 1.4rem;
            line-height: 28px;
            text-transform: initial;
            border-radius: 5px;
            span {
                display: block;
            }
        }
        &::after {
            content: url(/img/ui/arrow-down.svg);
            position: absolute;
            bottom: 20px;
            right: 20px;
            width: 9px;
            height: 5px;
        }
        &-subcategory {
            color: $color-blue;
        }
    }
    &-summary {
        &-text {
            height: auto;
            overflow: hidden;
            transition: max-height .1s ease; // Not working
            -moz-transition: max-height .5s;
            -ms-transition: max-height .5s;
            -o-transition: max-height .5s;
            -webkit-transition: max-height .5s;
        }
        &-more {
            display: flex;
            margin-top: 20px;
            cursor: pointer;
            height: 20px;


            &-dot {
                height: 3px;
                width: 3px;
                background-color: $color-devil-gray;
                border-radius: 50%;
                transition: transform .5s ease;

                &:nth-child(2) {
                    margin: 0 3px;
                }
            }

        }
        &-update {
            font-size: 1.2rem;
            text-align: right;
            color: $color-silver;
        }
        @include responsive-lg() {
            font-size: 1.6rem;
            position: relative;
        }
    }
    .card {
        width: auto;
        margin-bottom: 15px;
    }
    &-categorieslist {
        display: none;
        @include responsive-lg() {
            display: block;
            background-color: $color-white;
            position: absolute;
            width: 90%;
            border-radius: 5px;
            overflow-x: auto;
            top: 0;

            h2 {
                font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                font-size: 1.4rem;
            }
            ul {
                li {
                    &.active {
                        a {
                            color: $color-blue;
                        }
                    }
                }
            }
            &.sticky {
                position: fixed;
                top: 0;
            }
        }
    }

    // Spécifique à la liste des articles (une seule colonne)
    &-articles {

        padding: 0;
        margin: auto;
        nav {
            .breadcrumb {
                margin: auto;
                display: block;

                &-item {
                    display: inline;
                }
            }
        }

        &-list {
            .card {
                .img-fluid {
                    max-height: 100%;
                }
            }
        }

        .businesslist-summary-more {
            margin-left: 49%; // Centrer
        }
    }

    &-search {
        &-list {
            .card {
                height: auto;
            }
        }
    }
}
