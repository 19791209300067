.footer {
    background-color: $color-white;
    position: relative;
    padding-top: 60px;
    font-size: 1.4rem;
    margin-top: 20px;
    a {
        color: $color-text-dark;
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
    }
    &-shape {
        overflow: hidden;
        -moz-transform: scaleY(-1);    /* Gecko */
        -o-transform: scaleY(-1);      /* Opera */
        -webkit-transform: scaleY(-1); /* Webkit */
        transform: scaleY(-1);         /* Standard */
        position: absolute;
        top: 0;
        left: 0;
        height: 45px;
        display: flex;
        &-left {
            width: 50vw;
            svg {
                width: 100%;
            }
        }
        &-right {
            width: 50vw;
            -moz-transform: scaleX(-1);    /* Gecko */
            -o-transform: scaleX(-1);      /* Opera */
            -webkit-transform: scaleX(-1); /* Webkit */
            transform: scaleX(-1);         /* Standard */
            filter: FlipH;
            svg {
                width: 100%;
            }
        }
    }
    &-menu {
        line-height: 32px;
        @include responsive-md() {
            columns: 2;
        }
    }
    &-networks {
        text-align: right;
        a {
            display: inline-block;
            height: 22px;
            background-position: center center;
            background-repeat: no-repeat;
        }
        &-facebook {
            width: 13px;
            background-image: url(/img/ui/facebook.svg);
        }
        &-twitter {
            width: 24px;
            margin: 0 25px;
            background-image: url(/img/ui/twitter.svg);
        }
        &-linkedin {
            width: 22px;
            background-image: url(/img/ui/linkedin.svg);
        }
        &-viadeo {
            width: 22px;
            background-image: url(/img/ui/viadeo.svg);
        }
    }
    &-legal {
        margin-top: 50px;
        a {
            font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
            font-size: 1.2rem;
        }
        @include responsive-md() {
            margin-top: 0;
        }
    }
    &-toplink {
        padding: 20px 30px;
        font-size: 1.4rem;
        color: $color-text-dark;
        background-color: $color-white;
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        svg {
            margin-right: 20px;
        }
        &:hover {
            background-color: $color-blue;
            color: $color-white;
            text-decoration: none;
            svg {
                g,
                path {
                    fill: $color-white;
                }
            }
        }
        @include responsive-lg() {
            width: 420px;
            padding: 35px 60px;
            font-size: 1.8rem;
        }
    }
}
