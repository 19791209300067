.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(102, 102, 102, .1);
    border-left: 0;
    border-right: 0;
    padding: 8px 0;
    &-totalresults {
        font-size: 1.8rem;
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        @include responsive-lg() {
            border-right: 1px solid rgba(102, 102, 102, .1);
            padding-right: 15px;
            margin-right: 30px;
        }
    }
    &-sortby {
        button {
            padding: 6px 15px;
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            outline: none;
            span {
                font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
                margin: 0 12px;
            }
        }
    }
    &-filterby {
        display: none;
        @include responsive-lg() {
            display: block;
            span {
                font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                margin-right: 20px;
            }
            a {
                color: $color-silver;
                margin-right: 10px;
                &.active {
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                    color: $color-blue;
                }
            }
        }
    }
    .dropdown-menu {
        width: 90%;
        left: 10px!important;
        border-radius: 0;
        border-color: transparent;
    }
}
