//------------------------------------------------------------
// Screen >= 1200px
//------------------------------------------------------------
// @mixin responsive-xl() {
//     @media (max-width: $screen-md-max) {
//         @content;
//     }
// }

//------------------------------------------------------------
// Screen >= 992px
//------------------------------------------------------------
@mixin responsive-lg() {
    @media (min-width: 992px) {
        @content;
    }
}

//------------------------------------------------------------
// Screen >= 768px
//------------------------------------------------------------

@mixin responsive-md() {
    @media (min-width: 768px) {
        @content;
    }
}
//------------------------------------------------------------
// Screen >= 576px
//------------------------------------------------------------

@mixin responsive-sm() {
    @media (min-width: 576px) {
        @content;
    }
}
//------------------------------------------------------------
// Screen >= 480px
//------------------------------------------------------------
//------------------------------------------------------------
@mixin responsive-xs() {
    @media (min-width: 480px) {
        @content;
    }
}
//------------------------------------------------------------
// Screen > 576px
//------------------------------------------------------------
// @mixin responsive-xs() {
//     @media (min-width: $screen-xs-max) {
//         @content;
//     }
// }
