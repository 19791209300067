.review {
    border-top: 1px solid rgba(102, 102, 102, .1);
    padding: 25px 0 50px 0;
    &-picture {
        img {
            border-radius: 50%!important;
            width: 85px!important;
            height: 85px;
        }
    }
    &-date {
        font-size: 1.4rem;
        line-height: 15px;
        padding-top: 6px;
        @include responsive-lg() {
            position: absolute;
            left: 0;
            width: 100%;
        }
    }
    &-rate {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        &-stars {
            display: flex;
            &-star {
                display: block;
                height: 17px;
                width: 18px;
                background: url(../img/ui/star.svg) center center;
                background-size: 100%;
                margin-right: 6px;
                &--disabled {
                    background: url(../img/ui/star-disabled.svg) center center;
                    background-size: 100%;
                }
            }
        }
        &-mark {
            color: $color-blue;
            font-size: 2rem;
            margin-left: 15px;
        }
        @include responsive-lg() {
            justify-content: flex-start;
        }
    }
    &-content {
        font-size: 1.8rem;
        @include responsive-lg() {
            max-width: 500px;
        }
        &-author {
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            font-size: 1.4rem;
        }
    }
}
