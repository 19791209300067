.picturebanner {
    background: linear-gradient(0deg, rgba(24, 151, 235, 1) 0%, rgba(61, 99, 255, 1) 100%), url(/img/img1.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-blend-mode: multiply;
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-size: 2rem;
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        line-height: 26px;
        color: $color-white;
        text-align: center;
        margin: 0;
        @include responsive-lg() {
            font-size: 4rem;
            line-height: 50px;
        }
    }
    @include responsive-lg() {
        height: 235px;
    }
}
