.simplepage {
    &-wrapper {
        padding: 75px 15px 80px 15px;
        font-size: 1.6rem;
        background-color: $color-white;
        //margin-top: -200px;
        position: relative;
        border-radius: 5px;
        h2 {
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            font-size: 2rem;
            margin: 40px 0 20px 0;
            color: black;
        }
        h1 {
            text-align: center;
            @include responsive-lg() {
                text-align: initial;
            }
        }
        &--no-background {
            background-color: $color-background;
        }
        @include responsive-lg() {
            padding: 25px 85px 80px 85px;
        }
    }

}
