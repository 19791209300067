.postlist {
    .simplepage-wrapper {
        padding-bottom: 10px;
    }
    &-item {
        height: 110px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        margin-bottom: 20px;
        background-color: $color-white;
        &-image {
            background-position: center center;
            background-size: cover;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &-title {
            font-family: 'FuturaPTBook'!important;
            font-size: 1.8rem!important;
            margin: 20px 0 0!important;
            @include responsive-lg() {
                font-size: 3rem!important;
            }
        }
        &-date {
            font-size: 1.2rem;
            margin: 10px 0;
            display: block;
        }
        &-summary {
            display: none;
            @include responsive-lg() {
                display: block;
            }
        }
        @include responsive-lg() {
            height: 180px;
        }
    }
}
