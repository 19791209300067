.button {
    padding: 10px 15px;
    border: 0;
    font-size: 1.3rem;
    text-transform: uppercase;
    background-color: $color-white;
    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
    letter-spacing: 1.86px;
    // Styles
    &--rounded {
        border-radius: 25px;
    }
    &--full-width {
        width: 100%;
        text-align: center;
        display: inline-block;
    }
    &--small {
        font-size: 1.1rem;
    }
    &--select {
        // Buttons wich toggle a popin to replace a select/option input
        text-align: left;
        font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
        text-transform: initial;
        color: $color-devil-gray;
        letter-spacing: initial;
        &::after {
            content: url(/img/ui/arrow-down.svg);
            //float: right;
        }
    }
    &--bkg-orange {
        color: $color-white;
        background-color: $color-orange;
    }
    &--bkg-transparent {
        color: $color-white;
        background-color: transparent;
        text-transform: none;
    }
    &--bkg-blue {
        color: $color-white;
        background-color: #2156CD;
    }
}

a.button {
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: $color-white;
    }
}
