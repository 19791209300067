.contactcard {
    font-size: 1.4rem;
    color: $color-devil-gray;
    margin-bottom: 30px;
    &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-img {
            margin-right: 15px;
            img {
                border-radius: 50%!important;
                max-height: 110px;
            }
        }
    }
    &-body {
        margin: 10px 0 0 0;
        &-contactinfo {
            list-style: none;
            padding: 0;
            line-height: 24px;
            &-phone,
            &-fax,
            &-cell {
                position: relative;
                padding-left: 25px;
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &-phone {
                &::before {
                    content: url(/img/ui/phone.svg);
                }
            }
            &-fax {
                &::before {
                    content: url(/img/ui/fax.svg);
                }
            }
            &-cell {
                &::before {
                    content: url(/img/ui/smartphone.svg);
                }
            }
        }
    }

    &-networks {
        a {
            display: inline-block;
        }

        &-linkedin {
            margin-left: 30px;
            width: 22px;
            height: 22px;
            background-image: url(/img/ui/linkedin.svg);
            transform: scale(0.5);
        }
        &-viadeo {
            margin-left: 20px;
            width: 19px;
            height: 22px;
            background-image: url(/img/ui/viadeo.svg);
            transform: scale(0.5);
        }
    }

    &-footer {
        padding: 10px;
    }
}
