.section {
    margin-bottom: 30px;
    padding-bottom: 30px;
    h1, h2 {
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        font-size: 1.4rem;
        color: $color-text-dark;
        span {
            font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
            margin-left: 7px;
        }
    }
}
