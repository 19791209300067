.card-slider {
    display: flex;
    flex-wrap: wrap;

    &--lastnews {
        div.card-container {
            @media (max-width: 767px) {
                width: 125px;
            }
        }
    }

    .card-container {
        width: 270px;

        @media (max-width: 767px) {
            width: 100%;
        }

        &--col-12 {
            @include responsive-md {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        &--col-6 {
            @include responsive-md {
                width: calc(50% - 7.5px);
                margin-bottom: 15px;
            }

            &:nth-of-type(odd) {
                @include responsive-md {
                    margin-right: 7.5px;
                }
            }

            &:nth-of-type(even) {
                @include responsive-md {
                    margin-left: 7.5px;
                }
            }

            .card-news {
                .card-img {
                    height: 65px;
                    @include responsive-md {
                        height: 180px;
                    }
                }
                .card-title {
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                    height: 48px;
                }
            }
        }

        &--col-4 {
            @include responsive-md {
                width: calc(33% - 10px);
                margin-bottom: 15px;
                margin-right: 17px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .card-news {
                .card-img {
                    height: 65px;
                    @include responsive-md {
                        height: 130px;
                    }
                }
                .card-title {
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                    height: 48px;
                }
            }
        }

        &--col-3-md-12 {
            width: 100%;
            margin-bottom: 15px;
            @include responsive-md {
                width: calc(25% - 11.25px);
            }

            & + .card-container--col-3-md-12 {
                @include responsive-md {
                    margin-left: 15px;
                }
            }
            &:nth-of-type(4n+1) {
                margin-left: 0;
            }
        }
    }

    .owl {
        &-stage {
            display: flex;
        }
        &-item {
            float: none;

            .card-container {
                height: 100%;
            }

            &:last-of-type {
                .card-container {
                    @media (max-width: 767px) {
                        margin-right: 0;
                    }
                }
            }
            .card-container {
                @media (max-width: 767px) {
                    margin-right: 15px;
                }
            }
        }
    }
}

.card {
    position: relative;
    margin-bottom: 0 !important;
    padding: 0 5px;
    border: 0;
    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
    background-color: transparent;
    &-header {
        background: transparent;
        border-bottom: 0;
        border-top: 1px solid rgba(102, 102, 102, .1);;
    }
    &-body {
        border-radius: 5px;
        background-color: $color-white;
        position: relative;

        .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &-title {
        a {
            &,
            &:active,
            &:hover,
            &:visited {
                color: $color-half-devil-gray;
            }
        }
    }
    &-img {
        position: relative;
        display: block;
        width: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.05);
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-picture {
        width: 100%;
        height: 65px;
        background-size: cover;
        background-position: center center;
        @include responsive-lg() {
            height: 180px;
        }
    }
    &-footer {
        padding: 1.5rem 1.25rem;
        background-color: $color-white;
        border-bottom-left-radius: 5px!important;
        border-bottom-right-radius: 5px!important;
        display: none;
        @include responsive-lg() {
            display: block;
        }
    }
    &.col-lg-4 {
        .card-picture {
            @include responsive-lg() {
                height: 125px;
            }
        }
    }
    &-business {
        height: 100%;
        min-height: 170px;
        width: 100%;
        padding: 0;
        .card {
            &-title {
                font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                font-size: 2rem;
            }
            &-body {
                font-size: 1.3rem;
                line-height: 13px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                img {
                    width: 100%;
                }
            }
            &-metas {
                font-size: 1rem;
                &-type {
                    color: $color-devil-gray;
                }
                &-evaluation {
                    color: $color-blue;
                    p {
                        display: inline-block;
                        span {
                            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                        }
                    }
                    &::after {
                        content: url(/img/ui/star.svg);
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 8px;
                    }
                }
            }
            &-footer {
                background-color: $color-white;
                border-top: 1px solid $color-background;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                a {
                    font-size: 1.4rem;
                    color: $color-blue;
                }
                &-details {
                    a {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        margin: 0 5px;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        &.disabled {
                            opacity: .4;
                        }
                    }
                    &-onlinestore {
                        background-image: url(/img/ui/paiement.svg);
                    }
                    &-video {
                        background-image: url(/img/ui/video.svg);
                    }
                    &-building {
                        background-image: url(/img/ui/travaux.svg);
                    }
                }
            }
        }
        &--vif {
            .card-body {
                &::after {
                    content: url(/img/ui/vif.svg);
                    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
        &--minified {
            height: 99px;
            min-height: inherit;
            margin-bottom: 10px;
            .card-title {
                font-size: 1.8rem;
                margin-bottom: 0;
            }
            .card-body {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 100%;
            }
            .card-body-inner {
                height: 100%;
                overflow: hidden;
            }
        }
    }
    &-news {
        height: 100% !important;
        min-height: 160px;
        width: 100%;
        padding: 0;
        .card-picture {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .card-title {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            font-size: 1.4rem;
            line-height: 16px;
            margin-bottom: 0;
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
            @include responsive-lg() {
                font-size: 2rem;
                line-height: 24px;
            }
        }
        .card-body {
            background-color: $color-white!important;
        }
        .card-footer {
            border-top: 0;
            padding: 0 15px;
        }
        .card-metas {
            font-size: 1rem;
            color: $color-devil-gray;
            display: none;
            @include responsive-lg() {
                display: block;
                margin-top: 10px;
            }
        }
        &--new {
            .card-body {
                position: relative;
                &::before {
                    content: 'Nouveau';
                    width: 50px;
                    height: 11px;
                    background-color: $color-blue;
                    color: $color-white;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: -11px;
                    font-size: .9rem;
                    line-height: 12px;
                }
            }
        }
        &--horizontal {
            min-height: 150px;
            display: flex;
            flex-direction: row;
            margin-bottom:15px !important;
            .card-picture {
                width: 65px;
                height: 100%;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            .card-body {
                padding: 15px;
                height: 50px;
                @include responsive-xs {
                    height: 75px;
                }
                @include responsive-sm {
                    height: 100px;
                }
                @include responsive-md {
                    height: 150px;
                }
                @include responsive-lg {
                    height: 180px;
                }
            }
            .card-img {
                width: 75px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                @include responsive-xs {
                    width: 100px;
                }
                @include responsive-sm {
                    width: 150px;
                }
                @include responsive-md {
                    width: 200px;
                }
                @include responsive-lg {
                    width: 230px;
                }
            }
            @include responsive-lg() {
                height: 100px;
                .card-picture {
                    width: 125px;
                    flex: 1 0 auto;
                }
            }
        }
        height: initial;
    }
    @include responsive-lg() {
        margin-bottom: 15px;
    }
}
