.form-control {
    border-radius: 20px;
    border: 1px solid $color-silver;
    background-color: $color-background;
    min-height: 40px;
    font-size: 1.6rem;
}

label,
legend {
    font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
    margin-bottom: 5px;
}

button[type="submit"] {
    width: 100%;
    @include responsive-lg() {
        width: initial;
    }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    padding-right: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $color-devil-gray;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: $color-background;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $color-blue;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}

.input {
    transition: opacity .5s ease-in;
    opacity: 1;

    &--hidden {
        display: none;
        opacity: 0;
    }
}

.checkboxContainer {
    display:inline-block;
    margin-right: 10px;

    .form-control {
        width: 20px;
        height: 20px;
        min-height: auto;
        cursor: pointer;
    }
}