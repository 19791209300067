.popin {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: $color-white;
    width: 100vw;
    height: 100vh;
    padding: 20px 15px;
    text-align: left;
    font-size: 1.6rem;
    h3,
    h4 {
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        margin-bottom: 20px;
        display: inline-block;
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-size: 1.6rem;
    }
    &-list {
        list-style-type: none;
        padding: 0;
        line-height: 26px;
        overflow-y: auto;
        li {
            cursor: pointer;
            a {
                color: $color-text-dark;
            }
        }
        @include responsive-md() {
            font-size: 1.4rem;
        }
        li.selected {
            color: $color-blue;
        }

        .category-parent {
            font-size: inherit;
        }
        .category-child {
            font-size: inherit;
            display: inline;
        }
    }
    &-close {
        display: inline-block;
        float: right;
        width: 14px!important;
        height: 14px!important;
        background-color: $color-white;
        background-image: url(/img/ui/close.svg);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        border: 0;
    }
    // Popin localisation
    #regions,
    #domtom,
    #francophonie {
        height: 41vh;
        overflow-y: auto;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(102, 102, 102, .1);
    }
    .regions-list {
        @include responsive-lg() {
            margin-bottom: 60px;
            padding-bottom: 60px;
        }
    }

    // Popin categories
    .categories-list-responsive {
        height: 41vh;
        overflow-y: auto;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(102, 102, 102, .1);
    }
    .subRubriques-responsive {
        height: 20vh;
        border-bottom: 1px solid rgba(102, 102, 102, .1);

        .owl-carousel {
            list-style: none;
            padding: 0;
        }

        li {
            margin-right: 40px;
        }
    }

    .categories-list {
        @include responsive-lg() {
            padding-bottom: 60px;
        }
    }

    .nav-link {
        padding: 0;
        color: $color-black;
        &.active {
            font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        }
    }

    .department-selection-link {
        &.selected {
            color: $color-blue!important;
        }
        @include responsive-md() {
            font-size: 1.4rem;
        }
    }

    .localisation-departments, .subRubriques {
        padding: 0;
        list-style: none;
        // display: flex;
        li {
            margin-right: 40px;
        }
        @include responsive-md() {
            padding: 0 15px;
            li {
                margin-right: 30px;
            }
        }
    }

    .localisation-departments {
        max-height: 20vh;
        overflow-y: auto;
        @include responsive-lg() {
            columns: 3;
            height: 170px;
        }
    }

    .subRubriques {
        @include responsive-lg() {
            columns: 5;
            height: 200px;
        }
    }

    .departments-selector {
        display: none;
        &.activated {
            display: block;
        }
    }

    &.expanded {
        display: block;
    }
    @include responsive-md() {
        position: absolute;
        top: initial;
        left: 15px;
        width: calc(100% - 30px);
        height: initial;
    }
}
