.header {
    background: linear-gradient(0deg, rgba(24, 151, 235, 1) 0%, rgba(61, 99, 255, 1) 100%);
    height: 485px;
    text-align: center;
    padding: 30px 15px;
    position: relative;
    &--home {
        height: 665px;
    }
    &--fixed {
        @include responsive-lg() {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 80px!important;
            z-index: 5;
            padding: 0!important;
            .header-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 1340px;
                height: 80px;
                margin: 0 auto;
            }
            .header-shape {
                display: none;
            }
            .header-menu {
                display: none!important;
            }
            .header-logo {
                margin: 0;
            }

            .searchbar {
                max-width: initial;
                width: 1000px;
                flex: 1 0 auto;
            }

            .searchbar-keywords {
                width: 50%;
            }
        }
    }
    &-logo {
        display: inline-block;
        margin: 0 auto;
    }
    &-menutoggle {
        float: right;
        font-size: 4.5rem;
        color: $color-white!important;
    }
    &-slider {
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        font-size: 1.6rem;
        color: $color-white;
        margin: 20px -15px 0 -15px;
        .owl-stage-outer {
            width: 100%;
        }
        &-item {
            p {
                margin: 0;
            }
        }
        .owl-dots {
            line-height: 0;
            margin-top: 20px;
            .owl-dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $color-white;
                opacity: .3;
                margin: 0 5px;
                &.active {
                    opacity: 1;
                }
            }
        }
        @include responsive-md {
            font-size: 3rem;
        }
        @include responsive-lg() {
            width: 60vw;
            margin: 45px auto 0;
        }
    }
    &-subscribetext {
        font-weight: 400;
        color: $color-white;
        font-size: 1.6rem;
        line-height: 21px;
        text-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
        margin-top: 0;
        margin-bottom: 1rem;
        @include responsive-md {
            font-size: 1.8rem;
        }
    }

    &-subscribe {
        margin-top: 30px;
        display: inline-block;
        width: calc(100% - 30px);
        position: absolute;
        bottom: 38px;
        z-index: 1;
        left: 15px;
        padding: 15px 20px!important;
        transform: translateY(20px);
        @include responsive-md() {
            width: auto;
            left: 50%;
            transform: translateX(-50%) translateY(20px);
            margin-top: 0;
            bottom: 0;
        }
    }

    &-menu {
        font-family: 'FuturaPTDemi', Helvetica, Arial, sans-serif;
        ul {
            margin-top: 40px;
            li {
                margin-bottom: 40px;
                a {
                    color: $color-black;
                }
            }
        }
        &-login {
            @include responsive-md {
                &::after {
                    content: url(/img/ui/lock.svg);
                    margin-left: 10px;
                }
            }
        }
        @include responsive-md {
            font-family: 'FuturaPTBook', Helvetica, Arial, sans-serif;
            nav {
                display: flex;
                justify-content: space-between;
            }
            ul {
                margin-top: 0;
                padding: 0;
                list-style: none;
                font-size: 1.3rem;
                li {
                    margin-bottom: 0;
                    display: inline-block;
                    a {
                        color: $color-white;
                        opacity: .6;
                    }
                }
                &:first-child {
                    li {
                        margin-right: 20px;
                    }
                }
                &:last-child {
                    li {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    &-shape {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 55px;
        display: flex;
        &-left {
            width: 50vw;
            svg {
                width: 100%;
            }
        }
        &-right {
            width: 50vw;
            transform: scaleX(-1);
            filter: FlipH;
            svg {
                width: 100%;
            }
        }
    }
    @include responsive-md() {
        height: 380px;
        padding: 15px;
        background: linear-gradient(0deg, rgba(24, 151, 235, 1) 0%, rgba(61, 99, 255, 1) 100%), url(/img/header-bkg.webp);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-blend-mode: multiply;

        &--home {
            height: 480px;
        }
    }
}
